import React from 'react';
import './files.scss'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateNewModal from './createNewModal'
import CopyFile from './copyFile'
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl, Modal, Spinner } from 'react-bootstrap';
import { PlusSquare, InfoSquareFill, ArrowUpShort, PeopleFill, ArrowDownShort, Share, ThreeDotsVertical, FolderSymlink, Pencil } from 'react-bootstrap-icons';
import { postActions, postSocial } from '../../helpers/request'
import { Link } from 'react-router-dom';
import Scene from './animOpener'

class Files extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         filter: '',
         fileList: [],
         fileListWithoutSort: [],
         NameIndex: 1,
         TypeIndex: 1,
         library: null,
         friendsList: [],
         userType: '',
         librarySelectedPeople: [],
         selectedLibrary: [],
         sharedLibraryList: [],
         selectedFriendForShare: '',
         show: false,
         readyToDeleteId: '',
         isLoading: true,
         readyToCopyId: '',
         readyToCopyIdForClass: '',
         readyToCopyForClassShareId: '',
         setShow: false,
         ShowInfo: false,
         setShowInfo: false,
         showDelete: false,
         showCopy: false,
         showVrModel: false,
         showShareLibrary: false,
         showSharedList: false,
         showClassCopy: false,
         selected3Dmodel: null,
         classFilteredForCopy: [],
         classesList: [],
         copyClassData: {},
         copyLibData: {}
      };
   }

   handleClose = (condi) => {
      this.setState({ show: false })
      if (condi) {
         this.getLibraryFilelist(this.state.selectedLibrary)
      }
   }

   handleCloseLibrary = () => {
      this.setState({ showShareLibrary: false })
   }

   handleCloseCopy = () => {
      this.setState({ showCopy: false })
   }

   handleCloseClassCopy = () => {
      this.setState({ showClassCopy: false })
   }

   handleCloseClassSharedList = () => {
      this.setState({ showSharedList: false })
   }

   handleCloseInfo = () => {
      this.setState({ showInfo: false })
   }

   handleCloseDelete = () => {
      this.setState({ showDelete: false })
   }

   handleCloseVrModel = () => {
      this.setState({ showVrModel: false })
   }

   handleOpen = () => {
      this.setState({ show: true })
   }

   handleOpenVrModel = () => {
      this.setState({ showVrModel: true })
   }

   handleOpenCopy = (item) => {
      let lib = this.state.library
      let filtered = lib.filter(el => el.ID !== item.LibraryID)
      this.setState({ libraryFilteredForCopy: filtered })
      this.setState({ showCopy: true })
      this.setState({ readyToCopyId: item })
   }

   handleOpenClassCopy = (item) => {
      this.setState({ classFilteredForCopy: this.state.classesList })
      this.setState({ showClassCopy: true })
      this.setState({ readyToCopyIdForClass: item })
   }

   handleOpenClassShare = (item) => {
      this.setState({ showShareLibrary: true })
      this.setState({ readyToCopyForClassShareId: this.state.selectedLibrary.ID })
   }

   handleOpenClassSharedList = () => {
      this.setState({ showSharedList: true })
   }

   handleOpenInfo = () => {
      this.setState({ showInfo: true })
   }

   handleOpenDelete = (item) => {
      this.setState({ readyToDeleteId: item.ID })
      this.setState({ showDelete: true })
   }

   handleFilter = (e) => {
      this.setState({ filter: e.target.value })
   }

   setCopyClassLib = (item) => {
      this.setState({ copyClassData: item })
   }

   setCopyLib = (item) => {
      this.setState({ copyLibData: item })
   }

   setFriend = (item) => {
      this.setState({ selectedFriendForShare: item })
   }

   getFriends = () => {
      const data = new FormData();
      data.append("UserID", localStorage.getItem('userID').replace(/['"]+/g, ''));
      // data.append("FriendID", "1");
      data.append("Action", "getFriends")
      postSocial(data).then((response) => {
         this.createFriendsObject(response)
      })
   }

   createFriendsObject = (data) => {
      let obj = []
      let n = data.friends.length
      for (let x = 0; x < n; x++) {
         let createObj = {
            Name: data.names[x],
            ID: data.friends[x],
            chats: data.chats[x]
         }
         obj.push(createObj)
      }
      this.setState({ friendsList: obj })
   }

   getClasses = () => {
      let data = {
         Action: "getUserClasses",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.setState({ classesList: response.Classes })
      })
   }

   copyFileToClass = () => {
      let data = {
         Action: "copyFileToClassFolder",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.state.copyClassData.ID,
         FileID: this.state.readyToCopyIdForClass.ID
      }
      postActions(data).then(() => {
         toast.success('File Copy Successfull', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseClassCopy()
      })
   }

   copyFileToLib = () => {
      let data = {
         Action: "copyFileToLib",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         CurrentLibID: this.state.selectedLibrary.ID,
         NextLibID: this.state.copyLibData.ID,
         FileID: this.state.readyToCopyId.ID
      }
      postActions(data).then(() => {
         toast.success('File Copy Successfull', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseCopy()
      })
   }

   shareToOtherUser = () => {
      let data = {
         Action: "shareLibrary",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         OtherUserID: this.state.selectedFriendForShare.ID,
         LibraryID: this.state.selectedLibrary.ID
      }
      postActions(data).then(() => {
         toast.success('Library Sharing Successful', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseLibrary()
      })
   }

   openNewTab = (item) => {
      if (item.Type === '3Dmodel') {
         this.setState({ selected3Dmodel: item }, this.handleOpenVrModel())
      } else {
         window.open("https://languagevr.io/" + item.Link)
      }
   }

   deleteFile = () => {
      let data = {
         Action: "deleteFile",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         CurrentLibID: localStorage.getItem('FList'),
         FileID: this.state.readyToDeleteId
      }
      postActions(data).then((response) => {
         this.getLibraryFilelist(this.state.selectedLibrary)
         toast.success('File deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseDelete()
      })
   }

   mimeType = () => {
   }

   unShareLib = (item) => {
      let data = {
         Action: "unshareLibrary",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         OtherUserID: item.ID,
         LibraryID: this.state.selectedLibrary.ID
      }
      postActions(data).then(() => {
         this.getSharedFileList()
         toast.success('Library unshared', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   mountAssignment = (item) => {
      this.props.history.push({
         pathname: "/classes/createNewAssignment",
         state: item
      })
   }

   getLibraryFilelist = (lib) => {
      return new Promise((resolve, reject) => {
         let userID = localStorage.getItem('userID').replace(/['"]+/g, '')
         let data = {
            Action: "getLibraryFileList",
            UserID: userID,
            LibraryID: lib.ID
         }
         localStorage.setItem('FList', lib.ID)
         postActions(data).then((response) => {
            if (response.Files) {
               let data = [...response.Files]
               this.setState({ fileList: response.Files })
               this.setState({ fileListWithoutSort: data })
            } else {
               this.setState({ fileList: [] })
               this.setState({ fileListWithoutSort: [] })
            }
            this.setState({ isLoading: false })
            resolve()
         })
      })
   }

   // 1 => TUTOR
   //"Occupation":"2",//learner
   getLibaryies = () => {
      let data = {
         Action: "getUserLibraries",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.setState({ library: response.Libraries })
         this.setState({ selectedLibrary: response.Libraries[0] })
         this.getSharedFileList()
         this.getLibraryFilelist(response.Libraries[0])
      })
   }

   choseListType = (type) => {
      let nI = this.state.NameIndex
      let tI = this.state.TypeIndex
      if (type === 'name') {
         this.setState({ TypeIndex: 1 })
         tI = this.state.TypeIndex
         if (this.state.NameIndex === 3) {
            this.setState({ NameIndex: 1 })
            nI = 1
         } else {
            this.setState({ NameIndex: ++this.state.NameIndex })
            nI++
         }
      } else {
         this.setState({ NameIndex: 1 })
         nI = this.state.NameIndex
         if (this.state.TypeIndex === 3) {
            this.setState({ TypeIndex: 1 })
            tI = 1
         } else {
            this.setState({ TypeIndex: ++this.state.TypeIndex })
            tI++
         }
      }
      if (type === 'name') {
         if (nI === 1) {
            this.setState({ fileList: [...this.state.fileListWithoutSort] })
         } else if (nI === 2) {
            let data = this.state.fileList.sort((a, b) => a.Name.localeCompare(b.Name))
            this.setState({ fileList: data })
         } else if (nI === 3) {
            let data = this.state.fileList.sort((a, b) => b.Name.localeCompare(a.Name))
            this.setState({ fileList: data })
         }
      } else {
         if (tI === 1) {
            this.setState({ fileList: [...this.state.fileListWithoutSort] })
         } else if (tI === 2) {
            let data = this.state.fileList.sort((a, b) => a.Type.localeCompare(b.Type))
            this.setState({ fileList: data })
         } else if (tI === 3) {
            let data = this.state.fileList.sort((a, b) => b.Type.localeCompare(a.Type))
            this.setState({ fileList: data })
         }
      }
   }

   getSharedFileList = () => {
      let data = {
         Action: "getLibraryAccessList",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         LibraryID: this.state.selectedLibrary.ID
      }
      postActions(data).then((res) => {
         if (res && res.Users) {
            this.setState({ sharedLibraryList: res.Users })
         } else {
            this.setState({ sharedLibraryList: [] })
         }
      }).catch(() => {
         this.setState({ sharedLibraryList: [] })
      })
   }

   goToEdit = (item) => {
      if (item.Type == 'Test') {
         this.props.history.push({
            pathname: "/createNewTest",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'WordMatch') {
         this.props.history.push({
            pathname: "/wordMatch",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'TrueFalse') {
         this.props.history.push({
            pathname: "/trueFalse",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'Reading') {
         this.props.history.push({
            pathname: "/reading",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'Blank') {
         this.props.history.push({
            pathname: "/fillintheblanks",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'DramaA') {
         this.props.history.push({
            pathname: "/dramaA",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'DramaB') {
         this.props.history.push({
            pathname: "/dramaB",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'DramaC') {
         this.props.history.push({
            pathname: "/dramaC",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'DramaD') {
         this.props.history.push({
            pathname: "/dramaD",
            state: { edit: true, id: item.ID }
         })
      } else if (item.Type == 'ConversationClub') {
         this.props.history.push({
            pathname: "/conversationclub",
            state: { edit: true, id: item.ID }
         })
      }
   }

   componentDidMount() {
      if (localStorage.getItem('type')) {
         this.setState({ userType: localStorage.getItem('type').replace(/['"]+/g, '') })
      }
      this.getLibaryies()
      this.getClasses()
      this.getFriends()
   }

   selectLibarary = (item) => {
      this.setState({ selectedLibrary: item })
      this.getLibraryFilelist(item).then(() => {
         this.getSharedFileList()
      })
   }
   render() {
      const { filter, fileList, sharedLibraryList } = this.state;
      const lowercasedFilter = filter.toLowerCase();
      const filteredData = fileList ? fileList.filter(item => {
         return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(lowercasedFilter)
         );
      }) : ''
      return (
         <Container>
            <Row className="labelBar">
               <Col className="label d-md-flex d-none d-sm-none d-md-none d-lg-flex d-xl-flex" expand="lg" xl={9}>
                  <div class="labelMain">{this.props.langs.files}</div>
                  {this.state.selectedLibrary && this.state.selectedLibrary.AuthLevel == 1 ?
                     <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25} /><span class="buttonText">{this.props.langs.newFile}</span></div>
                     :
                     ''
                  }
                  {this.state.userType && this.state.userType != 3 ?
                     <Link to="/newLibrary">
                        <div class="newButtonMain"><PlusSquare color="#577df7" size={25} /><span class="buttonText">{this.props.langs.newLibrary}</span></div>
                     </Link>
                     :
                     ''
                  }
                  <div class="infoButtonMain" onClick={this.handleOpenInfo}><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">{this.props.langs.info}</span></div>
                  {/* <div class="infoButtonMain" onClick={this.handleOpenVrModel}><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">show Anim</span></div> */}
               </Col>
               {this.state.userType && this.state.userType != 3 ?
                  <Col xl={3} className="d-md-block d-none d-sm-none d-md-none d-lg-none d-xl-flex" style={{ justifyContent: 'flex-end' }} expand="lg">
                     <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" className="selectLibrary" variant="secondary">
                           {this.props.langs.libraryList}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="drop">
                           {this.state.library && this.state.library.map(item =>
                              <Dropdown.Item onClick={() => this.selectLibarary(item)}>{item.Name}</Dropdown.Item>
                           )}
                        </Dropdown.Menu>
                     </Dropdown>
                  </Col>
                  :
                  ''
               }
               <Col collapseOnSelect className="label full d-xl-none d-lg-none" expand="sm">
                  <div class="labelMain">{this.props.langs.files}</div>
                  <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25} /><span class="buttonText">{this.props.langs.newFile}</span></div>
                  <div class="newButtonMain">
                     <Link to="/newLibrary">
                        <PlusSquare color="#577df7" size={25} /><span class="buttonText">{this.props.langs.newLibrary}</span>
                     </Link>
                  </div>
                  <div class="infoButtonMain" onClick={this.handleOpenInfo}><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">{this.props.langs.info}</span></div>
               </Col>
               {this.state.userType && this.state.userType != 3 ?
                  <Col sm={12} style={{ marginTop: '10px' }} className="d-xl-none d-lg-block">
                     <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" className="selectLibrary full" variant="secondary">
                           {this.props.langs.libraryList}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="drop full">
                           {this.state.library && this.state.library.map(item =>
                              <Dropdown.Item onClick={() => this.selectLibarary(item)}>{item.Name}</Dropdown.Item>
                           )}
                        </Dropdown.Menu>
                     </Dropdown>
                  </Col>
                  :
                  ''
               }
            </Row>
            {this.state.isLoading ?
               <div class="animCenter">
                  <Spinner animation="border" role="status">
                     <span className="visually-hidden">Loading...</span>
                  </Spinner>
               </div>
               :
               <Row className="pageRow">
                  <Col xl={12}>
                     <div class="settingBox files">
                        <Row className="topBarDiv">
                           <Col xl={6} className="filesLabel">
                              {this.state.selectedLibrary && this.state.selectedLibrary.Name}
                              {this.state.userType && this.state.userType != 3 && this.state.selectedLibrary.Name != 'Private Library' && this.state.selectedLibrary && this.state.selectedLibrary.AuthLevel == 1 ?
                                 <div class="topLabelButton">
                                    <Dropdown>
                                       <Dropdown.Toggle variant="success" className="customDrop" id="dropdown-basic">
                                          <ThreeDotsVertical color="#577df7" size={25} />
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu align="end">
                                          <Dropdown.Item onClick={() => this.handleOpenClassShare()}><Share color="#577df7" size={20} /><span class="buttonText">{this.props.langs.shareLibrary}</span></Dropdown.Item>
                                          <Dropdown.Item onClick={() => this.handleOpenClassSharedList()}><FolderSymlink color="#577df7" size={20} /><span class="buttonText">{this.props.langs.sharedLibraryList}</span></Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </div>
                                 :
                                 ''
                              }
                           </Col>
                           <Col xl={6} className="searchBarDiv">
                              <Form className="d-flex">
                                 <FormControl
                                    type="search"
                                    placeholder="Search"
                                    className="searchBar"
                                    aria-label="Search"
                                    value={filter}
                                    onChange={this.handleFilter}
                                 />
                                 {/* <Button className="searchButton">Search</Button> */}
                              </Form>
                           </Col>
                        </Row>
                        <Col className="scrollable" xl={12}>
                           <Table striped bordered hover>
                              <thead>
                                 <tr class="customTableRowLabel">
                                    <th onClick={() => this.choseListType('type')}>{this.props.langs.type}{this.state.TypeIndex === 1 ? '' : this.state.TypeIndex === 2 ? <ArrowUpShort color="#577df7" size={25} /> : <ArrowDownShort color="#577df7" size={25} />}</th>
                                    <th onClick={() => this.choseListType('name')}>{this.props.langs.name}{this.state.NameIndex === 1 ? '' : this.state.NameIndex === 2 ? <ArrowUpShort color="#577df7" size={25} /> : <ArrowDownShort color="#577df7" size={25} />}</th>
                                    {/* <th>Class Name</th> */}
                                    {/* <th>Date</th> */}
                                    <th class="actions">{this.props.langs.actions}</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {filteredData && filteredData.length ? filteredData.map(item =>
                                    <tr className="customTableRow" key={item.ID}>
                                       <td>{item.Type}</td>
                                       <td>{item.Name}</td>
                                       {/* <td>Listening</td> */}
                                       {/* <td>{item.Date}</td> */}
                                       <td class="actions">
                                          <Dropdown>
                                             <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownActions" variant="secondary">
                                                {/* <Pencil color="#fff" size={15} /> */}
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu variant="dark">
                                                {/* <Dropdown.Item >View</Dropdown.Item> */}
                                                {this.state.selectedLibrary && this.state.selectedLibrary.AuthLevel == 1 && !item.Link ? <Dropdown.Item onClick={() => this.goToEdit(item)}>{this.props.langs.edit}</Dropdown.Item> : ''}
                                                {!item.Link ? <Dropdown.Item onClick={() => this.mountAssignment(item)}>{this.props.langs.createAssignment}</Dropdown.Item> : ''}
                                                {/* <Dropdown.Item >Add to Class Folder</Dropdown.Item> */}
                                                <Dropdown.Item onClick={() => this.handleOpenClassCopy(item)}>{this.state.userType === '3' ? (item.Type === 'Image' ? this.props.langs.setAsEventBanner : this.props.langs.setAsPrompterText) : this.props.langs.copyToClassFolder}</Dropdown.Item>
                                                {this.state.userType === '3' ? '' : <Dropdown.Item onClick={() => this.handleOpenCopy(item)}>{this.props.langs.copyToSharedLibrary}</Dropdown.Item>}
                                                {item.Link ? <Dropdown.Item onClick={() => this.openNewTab(item)}>{this.props.langs.downloadView}</Dropdown.Item> : ''}
                                                {this.state.selectedLibrary && this.state.selectedLibrary.AuthLevel == 1 ? <Dropdown.Item onClick={() => this.handleOpenDelete(item)}>{this.props.langs.delete}</Dropdown.Item> : ''}
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </td>
                                    </tr>
                                 )
                                    :
                                    <td className="noUserFound" colSpan="4">{this.props.langs.noDataFound}</td>
                                 }
                              </tbody>
                           </Table>
                        </Col>
                     </div>
                  </Col>
               </Row>
            }
            <Modal
               show={this.state.show}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />New</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <CreateNewModal isFilesUploaded={this.handleClose} langs={this.props.langs} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                     Close
                  </Button>
                  {/* <Button variant="primary">Continue</Button> */}
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showVrModel}
               onHide={this.handleCloseVrModel}
               backdrop="static"
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />New</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Scene model={this.state.selected3Dmodel} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseVrModel}>
                     Close
                  </Button>
                  {/* <Button variant="primary">Continue</Button> */}
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showClassCopy}
               onHide={this.handleCloseClassCopy}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />Select File for copy</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <CopyFile langs={this.props.langs} libraryList={this.state.classFilteredForCopy} libraryId={this.state.selectedLibrary} file={this.state.readyToCopyIdForClass} sendData={this.setCopyClassLib} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseClassCopy}>
                     Close
                  </Button>
                  <Button className="copyButton" onClick={this.copyFileToClass}>Copy</Button>
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showCopy}
               onHide={this.handleCloseCopy}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />Select library for copy</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <CopyFile langs={this.props.langs} libraryList={this.state.libraryFilteredForCopy} libraryId={this.state.selectedLibrary} file={this.state.readyToCopyId} sendData={this.setCopyLib} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseCopy}>
                     Close
                  </Button>
                  <Button className="copyButton" onClick={this.copyFileToLib}>Copy</Button>
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showDelete}
               onHide={this.handleCloseDelete}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />Delete</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Are sure to delete this file ?
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseDelete}>
                     Cancel
                  </Button>
                  <Button variant="danger" onClick={this.deleteFile}>Delete</Button>
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showInfo}
               onHide={this.handleCloseInfo}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />Info</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Info text
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseInfo}>
                     Close
                  </Button>
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showShareLibrary}
               onHide={this.handleCloseLibrary}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PeopleFill color="#577df7" size={25} style={{ marginRight: "10px" }} />Share</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <CopyFile langs={this.props.langs} libraryList={this.state.friendsList} isShare={true} libraryId={this.state.selectedLibrary} file={this.state.selectedLibrary} sendData={this.setFriend} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseLibrary}>
                     Close
                  </Button>
                  <Button className="copyButton" onClick={this.shareToOtherUser}>Share</Button>
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showSharedList}
               onHide={this.handleCloseClassSharedList}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PeopleFill color="#577df7" size={25} style={{ marginRight: "10px" }} />User Library Access List</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Col className="scrollable" xl={12}>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              <th>Access</th>
                              <th>Name</th>
                              {/* <th>Class Name</th> */}
                              {/* <th>Date</th> */}
                              <th class="actions">Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {sharedLibraryList && sharedLibraryList.length ? sharedLibraryList.map(item =>
                              <tr className="customTableRow" key={item.ID}>
                                 <td>{item.Access}</td>
                                 <td>{item.Name}</td>
                                 {/* <td>Listening</td> */}
                                 {/* <td>{item.Date}</td> */}
                                 <td class="actions">
                                    <Button variant="danger" onClick={() => { this.unShareLib(item) }}>Unshare</Button>
                                 </td>
                              </tr>
                           )
                              :
                              <td className="noUserFound" colSpan="4">No Data found</td>
                           }
                        </tbody>
                     </Table>
                  </Col>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseClassSharedList}>
                     Close
                  </Button>
                  <Button className="copyButton" onClick={this.shareToOtherUser}>Share</Button>
               </Modal.Footer>
            </Modal>
         </Container>
      )
   }
}

export default Files;